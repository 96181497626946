.fixed_cont {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: var(--popover-z);
}

.back_drop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: var(--popover-backdrop-z);
}

.popover_cont {
  position: fixed;
  z-index: var(--popover-z);
  display: inline-block;
  width: auto;
  height: auto;
  background-color: white;
  box-shadow: var(--shadow-m);
  --triangle-width: 30px;
  --triangle-height: 30px;
  border-radius: 6px;
}
.triangle {
  position: absolute;
  width: var(--triangle-width);
  height: var(--triangle-height);
  background-color: white;
  z-index: -1;
}

.left_triangle,
.right_triangle {
  --triangle-width: 30px;
  --triangle-height: 50px;
}

.top_triangle,
.bottom_triangle {
  --triangle-width: 50px;
  --triangle-height: 30px;
}

.left_triangle {
  left: calc((var(--triangle-width) / 2) * -1);
  clip-path: polygon(0 0, 100% 0, 0 50%, 100% 100%, 100% 0);
}

.right_triangle {
  right: calc(-1 * (var(--triangle-width) / 2));
  clip-path: polygon(0 0, 100% 50%, 0 100%, 0 0);
}

.top_triangle {
  top: calc(-1 * var(--triangle-height) / 2);
  clip-path: polygon(50% 0, 100% 100%, 0 100%, 50% 0);
}

.bottom_triangle {
  bottom: calc(-1 * var(--triangle-height) / 2);
  clip-path: polygon(0 0, 100% 0, 50% 100%, 0 0);
}

.top_left,
.bottom_left {
  left: 0;
}

.top_right,
.bottom_right {
  right: 0;
}

.top_center,
.bottom_center {
  left: calc(50% - var(--triangle-width) / 2);
}

.left_right {
  top: calc(50% - var(--triangle-height) / 2);
}
