.container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  animation: slideInRight 0.3s ease forwards;
  background-color: white;
  overflow: auto;
}

@keyframes slideInRight {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0%);
  }
}

@media (min-width: 786px) {
  .container {
    top: 85px;
    left: 275px;
  }
}
