.back_btn {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  color: var(--dark-gray-color);
  background-color: white;
}

.top_left {
  top: 10px;
  left: 10px;
}

.top_right {
  top: 10px;
  right: 10px;
}
.bottom_left {
  bottom: 10px;
  left: 10px;
}
.bottom_right {
  bottom: 10px;
  right: 10px;
}
