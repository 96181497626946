.d-f {
  display: flex;
}
.f-r {
  flex-direction: row;
  flex-wrap: nowrap;
}
.f-c {
  flex-direction: column;
  flex-wrap: nowrap;
}

.f-w {
  flex-wrap: wrap;
}
